import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    code: '',
    fission_id: 0,
    union_id: '',
    wowebsiteB:'',
    userInfo:{},
    userLoaction:{},
  },
  mutations: {
    wowebsiteFun(state,value){
      state.wowebsiteB=value
    },
    userInfoFun(state,value){
      state.userInfo=value
    },
    userLoactionFun(state,value){
      state.userLoaction=value
    }
  },
  actions: {
  },
  modules: {
  }
})
