<template>
  <div id="app">
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
  </div>
</template>

<script>

export default {
  mounted() {
    localStorage.setItem('WxCodeUrl',location.href)
  }
}
</script>

<style lang="less">
*::-webkit-scrollbar {
  display: none;
}
#app{
  height: 100%;
}
</style>
